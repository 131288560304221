import React, { useContext, useEffect, useState } from 'react';
import {
  Text,
  Box,
  Flex,
  Heading,
  Input,
  VStack,
  InputGroup,
  InputLeftAddon,
  Link,
  Image,
  InputRightAddon,
} from '@chakra-ui/react';
import { Link as Route, useNavigate } from 'react-router-dom';
import envelope from '../../../public/icons/envelope.svg';
import lock from '../../../public/icons/lock.svg';
import google from '../../../public/icons/google-logo.svg';
import logo_2 from '../../../public/icons/logo_.svg';
import SideComponent from '../SideComponent';
import IsDesktop from '../../../utils/IsDesktop';
import { CustomToast } from '../../../components/toast/CustomToast';
import { mutateFunction, useMutation } from '../../../libs/apis';
import BrandButton from '../../../components/brandButton/BrandButton';
import { useForm } from 'react-hook-form';
import { loginSchema } from '../../../validators';
import { yupResolver } from '@hookform/resolvers/yup';
import ValidationError from '../../../validators/ValidationError';
import UserService from '../../../utils/UserService';
import { setIsAuthenticated } from '../../../redux-toolkit/reducers/authSlice';
import { useDispatch } from 'react-redux';
import {
  setUserInfo,
  setUserSubscriptionInfo,
} from '../../../redux-toolkit/reducers/userSlice';
import { setCompanyInfo } from '../../../redux-toolkit/reducers/companySlice';
import { setAllCompaniesInfo } from '../../../redux-toolkit/reducers/allCompaniesSlice';
import { BASE_URL } from '../../../helpers/constant';
import client from '../../../libs/client';
import {
  setAllUserMessages,
  addNewMessages,
} from '../../../redux-toolkit/reducers/messagesSlice';
import { UserContext } from '../../../context/UserContext';
import socketIOClient from 'socket.io-client';
import evaluateCompanyProfile from '../../../utils/evaluateCompanyProfile';
import { setExpertInfo } from '../../../redux-toolkit/reducers/expertSlice';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import LoginWithGoogle from '../GoogleSignIn';
import evaluateExpertProfile from '../../../utils/evaluateExpertProfile';

const LoginForm = () => {
  const isDesktop = IsDesktop();
  const navigate = useNavigate();
  const { addToast } = CustomToast();
  const { emitEvent, socket, setSocket, userInformation } =
    useContext(UserContext);

  const dispatch = useDispatch();

  const { mutate, isLoading } = useMutation(mutateFunction);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const connectSocket = () => {
    if (!socket) {
      const Socket = socketIOClient(BASE_URL, {
        extraHeaders: {
          authorization: `Bearer: ${UserService.getToken()}`,
        },
      });
      setSocket(Socket);
    }
  };

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    UserService.removeCompanyInfo();
    UserService.removeToken();
    UserService.removeUserInfo();
    UserService.removeUserSubscriptionInfo();
    UserService.removeAllCompaniesInfo();
  }, []);

  const formSubmit = async (data, e) => {
    e.preventDefault();

    mutate(
      { key: 'login', method: 'post', data },
      {
        onSuccess(res) {
          if (res.data) {
            const user = res?.data?.user;
            const company = res?.data?.company;
            const expert = res?.data?.expert;
            UserService.setUserInfo(user);
            UserService.setToken(res?.data?.token);
            UserService.setAllCompaniesInfo(company);
            UserService.setCompanyInfo(company[0] || null);
            UserService.setExpertInfo(expert);
            dispatch(setIsAuthenticated(true));
            dispatch(setUserInfo(user));
            dispatch(setCompanyInfo(company[0] || null));
            dispatch(setAllCompaniesInfo(company));
            dispatch(setExpertInfo(expert));
            connectSocket();

            client()
              .get(`${BASE_URL}messages`)
              .then((res) => {
                dispatch(setAllUserMessages(res?.data?.data?.messages));
              });

            client()
              .get(`${BASE_URL}users/${user.id}/subscriptions/current`)
              .then((res) => {
                dispatch(
                  setUserSubscriptionInfo(
                    res?.data?.currentSubscription?.currentSubscription,
                  ),
                );
                UserService.setUserSubscriptionInfo(
                  res?.data?.currentSubscription?.currentSubscription,
                );
              });

            addToast(res);
            if (company[0]?.status !== 'live' && user?.userTypeId === 1) {
              addToast(
                'Your account is in draft mode. Please update all company details in account settings',
                'warning',
              );
              setTimeout(() => {
                evaluateCompanyProfile(company, navigate);
              }, 1000);
            }
            if (company[0]?.status !== 'live' && user?.userTypeId === 2) {
              addToast(
                'Your account is in draft mode. Please update all company details in account settings',
                'warning',
              );
            }
            if (user?.userTypeId === 3 && expert?.status !== 'live') {
              addToast(
                'Your account is in draft mode. Please update all expert details in account settings',
                'warning',
              );
              setTimeout(() => {
                evaluateExpertProfile(
                  {
                    ...expert,
                    headline: user?.headline,
                    bio: user?.bio,
                    phone_number: user?.phone_number,
                  },
                  navigate,
                );
              }, 1000);
            }

            if (
              user?.userTypeId !== 2 &&
              user?.userTypeId !== 1 &&
              user?.userTypeId !== 3
            ) {
              navigate('/dashboard');
            } else {
              navigate('/feed');
            }
          }
        },

        onError(e) {
          addToast(e?.response?.data?.message, 'error');
        },
      },
    );
  };

  return (
    <Box
      h="100vh"
      display={isDesktop ? 'flex' : 'block'}
      justifyContent="center"
      alignItems="center"
      overflowX="hidden"
    >
      {isDesktop && (
        <Flex w="50%">
          <SideComponent />
        </Flex>
      )}
      <Flex
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        minW={isDesktop && '50%'}
      >
        <Box w={isDesktop && `80%`}>
          {!isDesktop && (
            <Flex justifyContent="center" mb={4}>
              <Image
                src={logo_2}
                boxSize="100px"
                className="mt-10"
                objectFit="cover"
              />
            </Flex>
          )}
          <Flex
            flexDir="column"
            h="100%"
            alignItems="flex-start"
            justifyContent="center"
            margin={`auto`}
          >
            <Flex
              flexDir="column"
              margin={!isDesktop && `auto`}
              mb={isDesktop ? 4 : 6}
            >
              <Heading color="brand.primary">Welcome back!</Heading>
              <Text color="gray">
                Don't have an Account?{' '}
                <Link
                  color="brand.primary"
                  as={Route}
                  to="/signUp"
                  fontWeight="bold"
                >
                  Sign Up
                </Link>
              </Text>
            </Flex>
            <Flex justifyContent={`center`}>
              <form onSubmit={handleSubmit(formSubmit)}>
                <VStack
                  className="max-w-[400px] mx-auto"
                  spacing={!isDesktop ? '14px' : '25px'}
                  alignItems="flex-start"
                  w={!isDesktop ? '90vw' : '400px'}
                >
                  <Flex flexDir="column" className="max-w-[400px] w-full">
                    <Text fontSize="14px" mb={2}>
                      Your Email
                    </Text>
                    <InputGroup>
                      <Flex
                        className="w-full"
                        borderWidth="1px"
                        borderColor="brand.primary"
                        borderRadius="5px"
                        alignItems="center"
                      >
                        <InputLeftAddon
                          borderWidth="0px"
                          bg=""
                          children={<Image src={envelope} size={30} />}
                        />
                        <Input
                          focusBorderColor="#"
                          type="email"
                          isRequired={false}
                          borderWidth="0px"
                          variant="outline"
                          {...register('email')}
                          name="email"
                          placeholder="yourname@example.com"
                          w={!isDesktop ? '100%' : '350px'}
                          h="50px"
                        />
                      </Flex>
                    </InputGroup>
                    <ValidationError message={errors.email?.message} />
                  </Flex>
                  <Flex flexDir="column" className="max-w-[400px] w-full">
                    <Text mb={2}>Your Password</Text>
                    <InputGroup>
                      <Flex
                        className="w-full"
                        borderWidth="1px"
                        borderColor="brand.primary"
                        borderRadius="5px"
                        alignItems="center"
                      >
                        <InputLeftAddon
                          borderWidth="0px"
                          bg=""
                          children={<Image src={lock} size={30} />}
                        />
                        <Input
                          focusBorderColor="#"
                          borderWidth="0px"
                          variant="outline"
                          {...register('password')}
                          isRequired={false}
                          name="password"
                          placeholder="At least 6 characters"
                          type={isVisible ? 'text' : 'password'}
                          w={!isDesktop ? '100%' : '350px'}
                          h="50px"
                        />
                        <InputRightAddon
                          borderWidth="0px"
                          bg=""
                          children={
                            isVisible ? (
                              <FaEye
                                onClick={() => {
                                  setIsVisible((prev) => !prev);
                                }}
                                className="cursor-pointer"
                                size={20}
                              />
                            ) : (
                              <FaEyeSlash
                                src={lock}
                                onClick={() => {
                                  setIsVisible((prev) => !prev);
                                }}
                                className="cursor-pointer"
                                size={20}
                              />
                            )
                          }
                        />
                      </Flex>
                    </InputGroup>
                    <ValidationError message={errors.password?.message} />
                  </Flex>
                  <Link as={Route} to="/forgot-password">
                    <Text color="green">Forgot Password?</Text>
                  </Link>
                  <VStack
                    w="100%"
                    className="max-w-[400px] w-full"
                    alignItems="flex-start"
                  >
                    <BrandButton
                      isLoading={isLoading}
                      width={isDesktop ? '400px' : '100%'}
                      text={'Sign in'}
                      type="submit"
                      colorScheme="blue"
                    />
                    <Flex
                      p={0}
                      w="100%"
                      alignItems="center"
                      justifyContent="center"
                      margin={`auto`}
                    >
                      <Box minW="45%" maxW="70%" h="3px" bg="grey" />
                      <Text ml={1} mr={1}>
                        OR
                      </Text>
                      <Box minW="45%" maxW="70%" h="3px" bg="grey" />
                    </Flex>
                    <LoginWithGoogle context="Sign in" />
                  </VStack>
                </VStack>
              </form>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
export default LoginForm;
