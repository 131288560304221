import { Avatar } from '@chakra-ui/avatar';
import { Box, HStack, Text, VStack, Flex } from '@chakra-ui/layout';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BrandButton from '../brandButton/BrandButton';
import ConnectionButton from '../connectionButton/ConnectionButton';
import classNames from 'classnames';

const NewSingleExpert = ({
  expert,
  isOpen,
  onToggle,
  setSelectedExpert,
  isSwiper = false,
}) => {
  const navigate = useNavigate();

  const bookCall = () => {
    setSelectedExpert(expert);
    onToggle();
  };

  const viewDetails = () => {
    navigate(`/view-user?userid=${expert?.id}&usertype=${expert?.userTypeId}`);
  };

  return (
    <Box
      style={{ flex: '1 1 300px' }}
      className={classNames({
        'w-[100%] rounded-lg  border border-[#D5DBE2] p-4': true,
        'min-w-[300px] max-w-[300px] sm:w-[45%] sm:max-w-[350px] xl:w-[43%] xl:max-w-[390px] 2xl:max-w-[300px]':
          !isSwiper,
      })}
    >
      <Box className="flex gap-2 items-center">
        <Avatar
          onClick={viewDetails}
          className="cursor-pointer"
          name={`${expert.first_name} ${expert.last_name}`}
          src={expert.profile_image}
        />
        <Box className="flex flex-col">
          <Text
            className="text-[#2F4B6E] font-semibold"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {`${expert.first_name} ${expert.last_name}`}
          </Text>
          <Text
            className="text-[#8A8A8A] text-sm font-semibold"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {`${expert?.position || ''}`}
          </Text>
        </Box>
      </Box>
      <Box className="py-4">
        <Text className="text-xs preview_description">{expert?.bio}</Text>
      </Box>
      <Box className="px-2">
        <ConnectionButton user={expert} />
      </Box>
    </Box>
  );
};
export default NewSingleExpert;
