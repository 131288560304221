import { useContext, useEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { CustomToast } from '../toast/CustomToast';
import {
  Text,
  Stack,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Input,
  Button,
  Textarea,
  Box,
  useDisclosure,
} from '@chakra-ui/react';
import BrandButton from '../brandButton/BrandButton';
import { Avatar } from 'antd';
import { mutateFunction } from '../../libs/apis';
import { useSelector } from 'react-redux';
import { UserContext } from '../../context/UserContext';
import AutoResizeTextarea from './AutoResizeTextArea';
import { TbLink, TbPhoto, TbCode } from 'react-icons/tb';
import { CloseIcon } from '@chakra-ui/icons';
import EmbedVideoLinkModal from '../modal/EmbedVideoLinkModal';
import EmbedHtmlCodeModal from '../modal/EmbedHtmlCodeModal';
import MediaDisplay from './MediaDisplay';
import ShareContentModal from '../modal/ShareContentModal';
import { sanitizeHtml, isValidEmbedCode } from '../../utils/sanitizeHtml';

const CreatePost = ({
  onClose = () => {},
  showCloseButton = true,
  refetchPost = () => {},
  appendToPostList = (data) => {},
}) => {
  const { mutate, isLoading } = useMutation(mutateFunction);
  const { addToast } = CustomToast();
  const [postMessage, setPostMessage] = useState('');
  const [link, setLink] = useState('');
  const { userInformation } = useContext(UserContext);
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const imagesInputRef = useRef(null);
  const [embedCode, setEmbedCode] = useState('');

  const {
    isOpen: createEmbedVideoIsOpen,
    onClose: onCloseCreateEmbedVideo,
    onOpen: onOpenCreateEmbedVideo,
  } = useDisclosure();

  const {
    isOpen: createEmbedHtmlIsOpen,
    onClose: onCloseCreateEmbedHtml,
    onOpen: onOpenCreateEmbedCode,
  } = useDisclosure();

  const queryClient = useQueryClient();
  const formSubmit = async (e) => {
    e.preventDefault();

    if (!postMessage.trim()) {
      addToast({ message: 'Please add some text' }, 'warning');
      return;
    }

    const attachments = [...images];
    let formData = new FormData();
    formData.append('text', postMessage.trim());
    formData.append('media', link.trim());
    if (embedCode) {
      formData.append('embedCode', embedCode);
    }
    for (let i = 0; i < attachments.length; i++) {
      formData.append('attachments', attachments[i]);
    }

    mutate(
      {
        key: 'post',
        method: 'post',
        data: formData,
      },
      {
        onSuccess(res) {
          addToast(res);
          onClose();
          setPostMessage('');
          setImages([]);
          setEmbedCode('');
          // appendToPostList({
          //   ...res.data.post,
          //   user: {
          //     first_name: userInformation.firstName,
          //     last_name: userInformation.lastName,
          //     profile_image: userInformation.profile_image,
          //   },
          // });
          queryClient.invalidateQueries(`feed`);
        },
        onError(e) {
          addToast({ message: 'Post was not created' }, 'error');
        },
      },
    );
  };
  const maxBytes = 3485760;

  const openImageUpload = (e) => {
    imagesInputRef.current.click();
  };

  const handleChangeForImage = (e) => {
    let isTooLarge = [...e.target.files].some((file) => file.size > maxBytes);
    if (isTooLarge) {
      setTimeout(() => {
        addToast({
          message: 'One or more files are greater than 3MB',
          type: 'warning',
        });
      }, 500);
      return;
    }
    if (e.target.files.length < 8) {
      setImages([...e.target.files]);
    } else {
      setTimeout(() => {
        addToast({ message: 'More than 7 files selected', type: 'warning' });
      }, 500);
    }
  };

  const clearLink = () => {
    setLink('');
  };

  const clearImage = (index) => {
    setImages((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const onAddLink = (value) => {
    setLink(value);
  };

  useEffect(() => {
    setImagePreviews([...images].map((file) => URL.createObjectURL(file)));
  }, [images]);

  // Add embed code handler
  const handleEmbedCode = (code) => {
    if (!code) {
      setEmbedCode('');
      return;
    }

    if (isValidEmbedCode(code)) {
      const sanitized = sanitizeHtml(code);
      setEmbedCode(sanitized);
    } else {
      addToast({
        message: 'Please enter a valid embed code (iframe, img, or embed div)',
        type: 'warning',
      });
    }
  };

  const onAddCode = (value) => {
    handleEmbedCode(value);
  };

  return (
    <Flex flexDirection="column">
      <EmbedVideoLinkModal
        isOpen={createEmbedVideoIsOpen}
        onClose={onCloseCreateEmbedVideo}
        videoLink={link}
        onAddLink={onAddLink}
      />

      <EmbedHtmlCodeModal
        isOpen={createEmbedHtmlIsOpen}
        onClose={onCloseCreateEmbedHtml}
        onAddCode={onAddCode}
      />
      <Box mt={'20px'} className="flex items-start gap-2">
        <Box>
          <Avatar
            src={userInformation.profile_image}
            name={`${userInformation.firstName} ${userInformation.lastName}`}
            size={'md'}
          />
        </Box>
        <Flex
          borderBottomWidth="1px"
          borderColor="brand.primary"
          alignItems="center"
          className="w-full"
        >
          <form className="w-full" onSubmit={formSubmit}>
            <AutoResizeTextarea
              focusBorderColor="#"
              borderWidth="0px"
              variant="outline"
              isRequired={true}
              name="postMessage"
              value={postMessage}
              onChange={(e) => setPostMessage(e.target.value)}
              placeholder="What are you thinking...."
              type="postMessage"
              w={'full'}
              maxLength={150}
            />
          </form>
        </Flex>
      </Box>
      <Box className="flex justify-end my-1">
        <Text
          className={`text-xs font-semibold ${postMessage.length < 150 ? 'text-gray-600' : 'text-red-800'}`}
        >
          {postMessage.length} / {150} characters
        </Text>
      </Box>
      <Box className={`w-full min-h-[50px]`}>
        <Box className="flex w-full gap-8 flex-wrap items-center ">
          {images &&
            images.length > 0 &&
            imagePreviews.map((preview, index) => {
              return (
                <Box className="relative">
                  <img
                    src={preview}
                    alt="Preview"
                    className="w-20 h-auto object-cover rounded"
                  />
                  <Box
                    onClick={() => {
                      clearImage(index);
                    }}
                    className="border-2 bg-white absolute top-0 right-[-5px] cursor-pointer border-red-400 rounded-full px-1 grid place-items-center"
                  >
                    <CloseIcon color="red.400" w={2} />
                  </Box>
                </Box>
              );
            })}
          {link ? (
            <Box className="relative">
              <Box className="w-30 h-auto object-cover rounded">
                <MediaDisplay media={[link]} />
              </Box>
              <Box
                onClick={clearLink}
                className="border-2 bg-white absolute top-0 right-[-5px] cursor-pointer border-red-400 rounded-full px-1 grid place-items-center"
              >
                <CloseIcon color="red.400" w={2} />
              </Box>
            </Box>
          ) : null}
          {embedCode ? (
            <Box className="relative">
              <Box className="w-30 h-auto object-cover rounded">
                <MediaDisplay media={[embedCode]} />
              </Box>
              <Box
                onClick={() => handleEmbedCode('')}
                className="border-2 bg-white absolute top-0 right-[-5px] cursor-pointer border-red-400 rounded-full px-1 grid place-items-center"
              >
                <CloseIcon color="red.400" w={2} />
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box className="flex flex-col gap-5 pt-2 xs:flex-row border-t items-center justify-between w-full">
        <Flex
          direction={{ base: 'column', sm: 'row' }}
          className="items-center  gap-5"
        >
          <Box
            onClick={openImageUpload}
            className="flex mt-2 xs:mt-0 items-center gap-2 hover:font-medium cursor-pointer"
          >
            <Input
              accept="image/*"
              id="image-attachments"
              type="file"
              onChange={(e) => handleChangeForImage(e)}
              name="attachments"
              key="image-attachments"
              hidden
              multiple
              ref={imagesInputRef}
            />
            <TbPhoto />
            <Text className="text-sm">Image</Text>
          </Box>
          <Box
            onClick={onOpenCreateEmbedVideo}
            className="flex items-center gap-2 hover:font-medium cursor-pointer"
          >
            <TbLink />
            <Text className="text-sm">Video</Text>
          </Box>
          <Box
            onClick={onOpenCreateEmbedCode}
            className="flex items-center gap-2 hover:font-medium cursor-pointer"
          >
            <TbCode />
            <Text className="text-sm">Embed</Text>
          </Box>
        </Flex>
        <Flex
          direction={{ base: 'column', sm: 'row' }}
          justify={showCloseButton ? 'space-between' : 'flex-end'}
        >
          {showCloseButton && (
            <BrandButton
              text="Close"
              onClick={onClose}
              w={{ base: 'full', sm: 'auto' }}
            />
          )}
          <BrandButton
            isLoading={isLoading}
            text="Post"
            onClick={formSubmit}
            type="submit"
            variant="md"
            bgColor="brand.primary"
            color="#fff"
            fontSize="14px"
            borderRadius={4}
            px={4}
            // w={{ base: 'full', sm: 'auto' }}
            _hover={{ background: 'brand.primary' }}
          />
        </Flex>
      </Box>
    </Flex>
  );
};

export default CreatePost;
