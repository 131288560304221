import React, { useState } from 'react';
import {
  Text,
  Stack,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  Textarea,
  Box,
} from '@chakra-ui/react';
import { AiOutlineCode } from 'react-icons/ai';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import html from 'react-syntax-highlighter/dist/esm/languages/hljs/xml';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { isValidEmbedCode } from '../../utils/sanitizeHtml';
import { CustomToast } from '../toast/CustomToast';

SyntaxHighlighter.registerLanguage('html', html);

const EmbedHtmlCodeModal = ({ isOpen, onClose, onAddCode }) => {
  const [htmlCode, setHtmlCode] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const { addToast } = CustomToast();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!htmlCode.trim()) {
      addToast({
        message: 'Please enter some HTML code',
        type: 'error',
        duration: 3000,
      });
      return;
    }

    if (!isValidEmbedCode(htmlCode)) {
      addToast({
        message: 'Please enter a valid iframe, image, or embed div code',
        type: 'error',
        duration: 3000,
      });
      return;
    }

    onAddCode(htmlCode);
    onClose();
  };

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={4}>
            <Flex flexDirection="column">
              <Stack spacing={6}>
                <Flex className="items-center gap-2" justify={'center'}>
                  <Text
                    fontSize={20}
                    fontWeight={700}
                    color="brand.primary"
                    align="center"
                  >
                    Add HTML Code
                  </Text>
                  <AiOutlineCode
                    style={{ color: 'rgba(0,0,0,0.7)', fontSize: '28px' }}
                  />
                </Flex>

                <Text fontSize={14} color="#000000">
                  Embed HTML code in your post
                </Text>

                <Textarea
                  value={htmlCode}
                  onChange={(e) => setHtmlCode(e.target.value)}
                  placeholder="<div>Your HTML code here...</div>"
                  size="lg"
                  fontFamily="mono"
                />

                <Button
                  onClick={() => setShowPreview(!showPreview)}
                  variant="ghost"
                  size="sm"
                >
                  {showPreview ? 'Hide Preview' : 'Show Preview'}
                </Button>

                {showPreview && htmlCode && (
                  <Stack spacing={2}>
                    <Text fontSize={14} fontWeight="medium">
                      Preview
                    </Text>
                    <Box borderRadius="md" p={4} bg="gray.50">
                      <SyntaxHighlighter language="html" style={docco}>
                        {htmlCode}
                      </SyntaxHighlighter>
                    </Box>
                  </Stack>
                )}

                <Flex gap={3} justify="flex-end">
                  <Button onClick={onClose} variant="ghost">
                    Cancel
                  </Button>
                  <Button onClick={handleSubmit} colorScheme="blue">
                    Embed Code
                  </Button>
                </Flex>
              </Stack>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default EmbedHtmlCodeModal;
