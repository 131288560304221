import {
  Box,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Avatar,
  Text,
  Flex,
  Button,
  Spinner,
  VStack,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { useData } from '../../../data';
import moment from 'moment';
import SearchBar from '../../../components/searchbar/SearchBar';
import Header from '../../../components/header/Header';
import OffersCountSection from './OffersCountSection';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { FiPause, FiPlay } from 'react-icons/fi';
import { mutateFunction, useMutation } from '../../../libs/apis';
import { CustomToast } from '../../../components/toast/CustomToast';
import ViewOfferDetails from './ViewOfferDetails';
import BrandButton from '../../../components/brandButton/BrandButton';
import AddOffer from './AddOffer';
import IsDesktop from '../../../utils/IsDesktop';
import client from '../../../libs/client';
import { BASE_URL } from '../../../helpers/constant';

const StartupOffers = () => {
  const [displayedListStatus, setDisplayedListStatus] = useState('all');
  const [offers, setOffers] = useState([]);
  const [displayedList, setDisplayedList] = useState([]);
  const {
    isOpen: offerDetailsIsOpen,
    onToggle: onToggleOfferDetails,
    onClose: onCloseOfferDetails,
  } = useDisclosure();
  const {
    isOpen: addOfferIsOpen,
    onToggle: onToggleAddOffer,
    onClose: onCloseAddNewOffer,
  } = useDisclosure();
  const [selectedOffer, setSelectedOffer] = useState(0);
  const changeUserView = (offerStatus) => {
    setDisplayedListStatus(offerStatus);
  };
  const { mutate } = useMutation(mutateFunction);
  const { addToast } = CustomToast();
  const isDesktop = IsDesktop();
  const containerRef = useRef();
  const [pageNo, setPageNo] = useState(1);
  const lastResultsCount = useRef(0);
  const [misc, setMisc] = useState(null);

  const addNewOffer = () => {
    onToggleAddOffer();
  };

  const [isLoading, setIsLoading] = useState(true);

  const updateState = (data, append = true, isFetchedFromServer = true) => {
    if (isFetchedFromServer) lastResultsCount.current = data.length;
    let _offers = append ? [...offers, ...data] : [...data];
    setIsLoading(false);
    setOffers([..._offers]);
  };

  const getOffers = async ({ filters = {}, append = true, pageNumber = 1 }) => {
    // const _filters = parseFilters(filters)
    return client()
      .get(`${BASE_URL}offers?page_no=${pageNumber}&page_size=10`)
      .then((res) => {
        setIsLoading(true);
        const { offers, ...rest } = res.data.data;
        setMisc(rest);
        updateState(offers, append);
      });
  };

  useEffect(() => {
    getOffers({});
  }, []);

  const sortList = (offerActiveStatus) => {
    if (offerActiveStatus === 'all') {
      return offers;
    }
    return offers.filter((offer) => offer.is_active === offerActiveStatus);
  };

  const changeStatus = (offerId) => {
    mutate(
      { key: `offers/${offerId}`, method: 'patch' },
      {
        onSuccess(res) {
          addToast(res);
          getOffers({});
        },
      },
    );
  };

  const deleteOffer = (offerId) => {
    mutate(
      { key: `offerDelete/${offerId}`, method: 'delete' },
      {
        onSuccess(res) {
          addToast(res);
          getOffers({});
        },
      },
    );
  };
  const viewOffer = (offer) => {
    setSelectedOffer(offer);
    onToggleOfferDetails();
  };
  const handleScroll = (
    scrollpos = window.innerHeight + window.scrollY,
    containerHeight = document.documentElement.offsetHeight,
  ) => {
    let userScrollHeight = scrollpos;
    let windowBottomHeight = containerHeight;

    if (userScrollHeight >= windowBottomHeight) {
      if (lastResultsCount.current < 1) {
        return;
      } else {
        let _pageNo = pageNo + 1;
        setPageNo(_pageNo);
        getOffers({ pageNumber: _pageNo });
      }
    }
  };

  useEffect(() => {
    if (offers) {
      setDisplayedList(sortList(displayedListStatus));
    }
  }, [offers?.length, displayedListStatus]);

  console.log('displayList', displayedList);
  return (
    <Box>
      <Header title="Startup Offers" />
      <Flex className={`flex-col px-3 py-5`}>
        {!isLoading ? (
          <>
            {offerDetailsIsOpen && (
              <ViewOfferDetails
                refetch={getOffers}
                brand={selectedOffer?.brand}
                offerId={selectedOffer?.id}
                onToggle={onCloseOfferDetails}
                changeStatus={changeStatus}
                deleteOffer={deleteOffer}
              />
            )}
            {addOfferIsOpen && (
              <AddOffer onToggle={onCloseAddNewOffer} refetch={getOffers} />
            )}
            {!offerDetailsIsOpen && !addOfferIsOpen && (
              <>
                <Flex w="100%">
                  <Box mt="20px" w="100%">
                    <HStack w="50%" justifyContent="space-between">
                      <OffersCountSection
                        totalCount={misc?.totalOffers}
                        totalGotten={misc?.totalGotten}
                      />
                      <BrandButton
                        text="Add New"
                        minW="170px"
                        onClick={addNewOffer}
                      />
                    </HStack>
                    <Flex w="100%" justify="space-between" alignItems="center">
                      <SearchBar />
                      <Flex alignItems="flex-start" pr={2} h="29px" w="67">
                        <Button
                          bg={
                            displayedListStatus === 'all'
                              ? 'brand.primary'
                              : '#FFFFFF'
                          }
                          color={
                            displayedListStatus === 'all'
                              ? '#FFFFFF'
                              : 'brand.primary'
                          }
                          _hover={{ bg: '#134A70', color: '#FFFFFF' }}
                          borderRadius="5px 0px 0px 5px"
                          boxShadow="base"
                          onClick={() => changeUserView('all')}
                        >
                          All
                        </Button>
                        <Button
                          bg={
                            displayedListStatus === true
                              ? 'brand.primary'
                              : '#FFFFFF'
                          }
                          color={
                            displayedListStatus === true
                              ? '#FFFFFF'
                              : 'brand.primary'
                          }
                          _hover={{ bg: '#134A70', color: '#FFFFFF' }}
                          boxShadow="base"
                          borderRadius="0px"
                          onClick={() => changeUserView(true)}
                        >
                          Ongoing
                        </Button>
                        <Button
                          bg={
                            displayedListStatus === false
                              ? 'brand.primary'
                              : '#FFFFFF'
                          }
                          color={
                            displayedListStatus === false
                              ? '#FFFFFF'
                              : 'brand.primary'
                          }
                          _hover={{ bg: '#134A70', color: '#FFFFFF' }}
                          boxShadow="base"
                          borderRadius="0px 5px 5px 0px"
                          onClick={() => changeUserView(false)}
                        >
                          Paused
                        </Button>
                      </Flex>
                    </Flex>
                  </Box>
                </Flex>
                {/* --------------------table */}
                <Box
                  ref={containerRef}
                  onScroll={() => {
                    handleScroll(
                      containerRef.current.scrollTop +
                        containerRef.current.clientHeight,
                      containerRef.current.scrollHeight,
                    );
                  }}
                  className={`flex-col w-full hide-scroll ${
                    isDesktop ? 'max-h-[75vh] mt-5 pb-32 overflow-y-scroll' : ''
                  }`}
                >
                  <TableContainer mt="50px" borderRadius="10px">
                    <Table variant="simple" size="sm" fontSize="12px">
                      <Tbody>
                        {displayedList?.map((items) => {
                          return (
                            <Tr
                              key={items.id}
                              style={{ padding: '2rem 2rem', height: '80px' }}
                              boxShadow="md"
                              borderRadius="10px"
                              _hover={{
                                borderWidth: '2px',
                                borderColor: 'brand.primary',
                                borderRadius: '10px',
                              }}
                            >
                              <Td
                                cursor="pointer"
                                onClick={() => viewOffer(items)}
                              >
                                <VStack
                                  justifyContent="center"
                                  alignItems="flex-start"
                                >
                                  <Text
                                    fontSize="16px"
                                    fontWeight={600}
                                    color="brand.primary"
                                  >
                                    {items?.title || 'N/A'}
                                  </Text>
                                  <Text fontSize="12px" color="gray">
                                    Added on{' '}
                                    {moment(items?.createdAt).format(
                                      `MMM D, YYYY`,
                                    )}
                                  </Text>
                                </VStack>
                              </Td>
                              <Td>
                                <Flex alignItems="center">
                                  <Avatar
                                    name={`${items?.brand?.name}`}
                                    src={items?.brand?.logo}
                                    alt=""
                                    size="sm"
                                    mr={2}
                                  />
                                  <Text>{`${items?.brand?.name}`}</Text>
                                </Flex>
                              </Td>
                              <Td>
                                <VStack>
                                  <Text
                                    fontSize="16px"
                                    fontWeight={600}
                                    color="brand.primary"
                                  >
                                    {items?.price}
                                  </Text>
                                  <Text fontSize="12px" color="gray">
                                    Offer Value
                                  </Text>
                                </VStack>
                              </Td>
                              <Td>
                                <VStack>
                                  <Text
                                    fontSize="16px"
                                    fontWeight={600}
                                    color="brand.primary"
                                  >
                                    {moment(items?.start_date).format(
                                      `DD/MM/YYYY`,
                                    )}
                                  </Text>
                                  <Text fontSize="12px" color="gray">
                                    Start date
                                  </Text>
                                </VStack>
                              </Td>
                              <Td>
                                <VStack>
                                  <Text
                                    fontSize="16px"
                                    fontWeight={600}
                                    color="brand.primary"
                                  >
                                    {moment(items?.end_date).format(
                                      `DD/MM/YYYY`,
                                    )}
                                  </Text>
                                  <Text fontSize="12px" color="gray">
                                    End date
                                  </Text>
                                </VStack>
                              </Td>
                              <Td>
                                <VStack>
                                  <Text
                                    fontSize="16px"
                                    fontWeight={600}
                                    color="brand.primary"
                                  >
                                    {items?.gotten || 0}
                                  </Text>
                                  <Text fontSize="12px" color="gray">
                                    Gotten
                                  </Text>
                                </VStack>
                              </Td>
                              <Td>
                                <HStack>
                                  {items?.is_active === true && (
                                    <Box
                                      borderRadius="5px"
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      w="120px"
                                      h="40px"
                                      bg="#E6F3E6"
                                    >
                                      <Text color="green" fontWeight={500}>
                                        Ongoing
                                      </Text>
                                    </Box>
                                  )}
                                  {items?.is_active === false && (
                                    <Box
                                      borderRadius="5px"
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      w="120px"
                                      h="40px"
                                      bg="#FFF6E6"
                                    >
                                      <Text color="orange" fontWeight={500}>
                                        Paused
                                      </Text>
                                    </Box>
                                  )}
                                  {items?.is_active === true && (
                                    <Box
                                      cursor="pointer"
                                      onClick={() => changeStatus(items.id)}
                                      border="1px"
                                      borderColor="orange"
                                      borderRadius="5px"
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      w="40px"
                                      h="40px"
                                    >
                                      <FiPause color="orange" />
                                    </Box>
                                  )}
                                  {items?.is_active === false && (
                                    <Box
                                      cursor="pointer"
                                      onClick={() => changeStatus(items.id)}
                                      border="1px"
                                      borderColor="green"
                                      borderRadius="5px"
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      w="40px"
                                      h="40px"
                                    >
                                      <FiPlay color="green" />
                                    </Box>
                                  )}
                                  <Box
                                    cursor="pointer"
                                    onClick={() => deleteOffer(items.id)}
                                    border="1px"
                                    borderColor="red"
                                    borderRadius="5px"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    w="40px"
                                    h="40px"
                                  >
                                    <RiDeleteBin6Line color="red" />
                                  </Box>
                                </HStack>
                              </Td>
                              <Td>
                                <BiDotsVerticalRounded />
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              </>
            )}
          </>
        ) : (
          <>
            <Spinner m={5} />
          </>
        )}
        {/* --------------------table */}
      </Flex>
    </Box>
  );
};

export default StartupOffers;
