import {
  Box,
  Flex,
  HStack,
  Input,
  InputGroup,
  Select,
  InputLeftAddon,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import BrandButton from '../../../components/brandButton/BrandButton';
import { CustomToast } from '../../../components/toast/CustomToast';
import { useData } from '../../../data';
import { mutateFunction, useMutation } from '../../../libs/apis';
import IsDesktop from '../../../utils/IsDesktop';
import { useClickOutside } from '../../../utils/useClickOutside';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

const schema = yup.object({
  title: yup.string().required('Title is required').max(60),
  price: yup
    .string()
    .required('Price is required')
    .matches(/^\d+$/, 'Only numbers are allowed'),
  caption: yup.string().required('Caption is required'),
  start_date: yup.string().required('Start date is  required'),
  end_date: yup.string().required('End date is required'),
  description: yup.string().required('Offer details is required'),
  offer_url: yup.string().required('Url is required'),
  brand_id: yup.string().optional(),
  category_id: yup.string().required('Category is required'),
});

const AddOffer = ({ onToggle, refetch }) => {
  const isDesktop = IsDesktop();
  const { data } = useData(`brands`);
  const [brands, setBrands] = useState([]);
  const { mutate, isLoading: mutateIsLoading } = useMutation(mutateFunction);
  const { addToast } = CustomToast();
  const { data: _offersCategoriesData } = useData('offers/categories');
  const list = _offersCategoriesData?.data?.categories || [];
  const [ref] = useClickOutside(() => {
    if (isOpen) setIsOpen(false);
  });
  const [selected, setSelected] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropDown = () => setIsOpen((prev) => !prev);
  const buttonRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: '',
      price: '',
      caption: '',
      start_date: '',
      end_date: '',
      description: '',
      offer_url: '',
      category_id: '',
    },
    mode: 'onBlur',
  });

  const addStartupOffer = (formData) => {
    formData = {
      ...formData,
      price: Number(formData?.price),
      category_id: Number(formData?.category_id),
    };

    if (!formData?.brand_id) {
      delete formData.brand_id;
    } else {
      formData = {
        ...formData,
        brand_id: Number(formData?.brand_id),
      };
    }

    mutate(
      {
        key: `offers`,
        method: 'post',
        data: {
          ...formData,
          category_id: selected?.id || null,
        },
      },
      {
        onSuccess(res) {
          addToast(res);
          refetch();
          setSelected(null);
          setSearchValue('');
          buttonRef?.current.click();
          reset();
          setTimeout(() => {
            buttonRef?.current.click();
            onToggle();
          }, 2000);
        },
        onError: (err) => {
          addToast(
            err?.response?.data?.message || 'Something went wrong!',
            'error',
          );
        },
      },
    );
  };

  useEffect(() => {
    if (data?.data) {
      setBrands(data?.data?.brands);
    }
  }, [data]);

  const searchCategoriesFilter = (value) => {
    return searchValue
      ? value?.title?.toLowerCase().includes(searchValue.toLowerCase()) ||
          value?.title == 'Other'
      : true;
  };

  const onChange = (e) => {
    setIsOpen(true);
    setSearchValue(e.target.value);
    setValue('category_id', e.target.id);
    setSelected(null);
  };

  const selectItem = (item) => {
    setSearchValue(item?.title);
    setSelected(item);
    setValue('category_id', item.id);
    toggleDropDown();
  };

  console.log('brands', brands);
  return (
    <VStack alignItems="flex-start">
      <Flex justifyContent="flex-start" alignItems="center" my={4} mx={4}>
        <Flex
          ref={buttonRef}
          cursor="pointer"
          onClick={onToggle}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Box mr={2}>
            <HiOutlineArrowNarrowLeft size="35px" />
          </Box>
          <Box>
            <Text fontWeight={600}>Back</Text>
          </Box>
        </Flex>
      </Flex>
      <form
        noValidate
        onSubmit={handleSubmit(addStartupOffer)}
        style={{ width: '100%' }}
      >
        <HStack
          className="overflow-y-scroll h-[70vh] pb-10 hide-scroll"
          w="100%"
          alignItems="flex-start"
          m={4}
        >
          <VStack w="60%" alignItems="flex-start">
            <Flex flexDir="column" w="100%" key="title">
              <Text fontSize="14px" mb={2}>
                Offer Title*
              </Text>
              <Input
                {...register('title')}
                borderWidth="1px"
                borderColor={'brand.primary'}
                borderRadius="5px"
                focusBorderColor="#"
                variant="outline"
                placeholder="Offer Title"
                type="text"
                w="100%"
                h="50px"
              />
              {errors.title && (
                <Text color="red.500" fontSize="sm" mt={1}>
                  {errors.title.message}
                </Text>
              )}
            </Flex>

            <Flex flexDir="column" w="100%" key="brand">
              <Text fontSize="14px" mb={2}>
                Select Brand
              </Text>
              <Select
                {...register('brand_id')}
                borderWidth="1px"
                borderColor={'brand.primary'}
                borderRadius="5px"
                focusBorderColor="#"
                placeholder="Brand"
              >
                {brands?.map((eachBrand) => (
                  <option key={eachBrand.id} value={eachBrand.id}>
                    {eachBrand.name}
                  </option>
                ))}
              </Select>
              {errors.brand_id && (
                <Text color="red.500" fontSize="sm" mt={1}>
                  {errors.brand_id.message}
                </Text>
              )}
            </Flex>

            <Flex flexDir="column" w="100%" key="value">
              <Text fontSize="14px" mb={2}>
                Offer Price*
              </Text>
              <Input
                {...register('price')}
                borderWidth="1px"
                borderColor={'brand.primary'}
                borderRadius="5px"
                focusBorderColor="#"
                variant="outline"
                placeholder="Offer price"
                type="text"
                w="100%"
                h="50px"
              />
              {errors.price && (
                <Text color="red.500" fontSize="sm" mt={1}>
                  {errors.price.message}
                </Text>
              )}
            </Flex>

            <Flex flexDir="column" w="100%" key="offer_url">
              <Text fontSize="14px" mb={2}>
                Offer URL*
              </Text>
              <Input
                {...register('offer_url')}
                borderWidth="1px"
                borderColor={'brand.primary'}
                borderRadius="5px"
                focusBorderColor="#"
                variant="outline"
                placeholder="Offer url"
                type="text"
                w="100%"
                h="50px"
              />
              {errors.offer_url && (
                <Text color="red.500" fontSize="sm" mt={1}>
                  {errors.offer_url.message}
                </Text>
              )}
            </Flex>

            <Flex flexDir="column" w="100%" key="caption">
              <Text fontSize="14px" mb={2}>
                Offer Caption*
              </Text>
              <Input
                {...register('caption')}
                borderWidth="1px"
                borderColor={'brand.primary'}
                borderRadius="5px"
                focusBorderColor="#"
                variant="outline"
                placeholder="Offer Caption"
                type="text"
                w="100%"
                h="50px"
              />
              {errors.caption && (
                <Text color="red.500" fontSize="sm" mt={1}>
                  {errors.caption.message}
                </Text>
              )}
            </Flex>

            <div className={`relative w-full`} ref={ref}>
              <Text fontSize="14px" mb={2}>
                Categories*
              </Text>
              <div
                ref={ref}
                className={`bg-white cursor-pointer flex justify-between items-center mb-2 max-w-2xl `}
              >
                <Input
                  value={searchValue}
                  onChange={onChange}
                  placeholder={'Category'}
                  className="border border-[#134A70] w-full py-3 pr-2 pl-4 rounded-lg outline-none"
                  borderRadius="10px"
                  borderWidth={1}
                  borderColor={'brand.primary'}
                  h="50px"
                  type="text"
                />
              </div>
              {errors.category_id && (
                <Text color="red.500" fontSize="sm" mt={1}>
                  {errors.category_id.message}
                </Text>
              )}
              {isOpen && list.length ? (
                <div className="absolute top-[80px] bg-white w-full max-w-2xl rounded-2xl py-2 flex flex-col gap-4 left-0 ad-card-shadow z-10">
                  <div
                    className={`flex flex-col gap-4 max-h-[30vh] overflow-y-auto pb-5`}
                  >
                    {list.filter(searchCategoriesFilter).map((item) => (
                      <div
                        className="cursor-pointer px-5 py-2 hover:bg-gray-100"
                        onClick={() => selectItem(item)}
                        key={item?.id}
                      >
                        {item?.title}
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>

            <Flex flexDir="column" w="100%">
              <HStack
                spacing={isDesktop ? 2 : 0}
                flexDir={isDesktop ? 'row' : 'column'}
                justifyContent="space-between"
                alignItems="center"
                w="100%"
              >
                <Flex flexDir="column" w="100%" key="start_date">
                  <Text fontSize="14px" mb={2}>
                    Start Date*
                  </Text>
                  <Input
                    {...register('start_date')}
                    borderWidth="1px"
                    borderColor={'brand.primary'}
                    borderRadius="5px"
                    type="date"
                    size="md"
                  />
                  {errors.start_date && (
                    <Text color="red.500" fontSize="sm" mt={1}>
                      {errors.start_date.message}
                    </Text>
                  )}
                </Flex>
                <Flex flexDir="column" w="100%" key="end_date">
                  <Text fontSize="14px" mb={2}>
                    End Date*
                  </Text>
                  <Input
                    {...register('end_date')}
                    borderWidth="1px"
                    borderColor={'brand.primary'}
                    borderRadius="5px"
                    type="date"
                    size="md"
                  />
                  {errors.end_date && (
                    <Text color="red.500" fontSize="sm" mt={1}>
                      {errors.end_date.message}
                    </Text>
                  )}
                </Flex>
              </HStack>
            </Flex>

            <BrandButton
              isLoading={mutateIsLoading}
              text="Add Offer"
              w="120px"
              colorScheme="blue"
              type="submit"
            />
          </VStack>

          <Flex
            flexDir="column"
            w="38%"
            h="100%"
            className="min-h-[500px]"
            key="details"
            mx={4}
          >
            <Text fontSize="14px" mb={2}>
              Offer Details*
            </Text>
            <Textarea
              {...register('description')}
              borderWidth="1px"
              borderColor={'brand.primary'}
              borderRadius="5px"
              focusBorderColor="#"
              variant="outline"
              placeholder="Offer Details"
              h="100%"
              w="100%"
            />
            {errors.description && (
              <Text color="red.500" fontSize="sm" mt={1}>
                {errors.description.message}
              </Text>
            )}
          </Flex>
        </HStack>
      </form>
    </VStack>
  );
};
export default AddOffer;
