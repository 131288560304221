import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  Fragment,
} from 'react';
import { useDisclosure } from '@chakra-ui/hooks';
import { Box, Flex, Spinner, Text, Avatar } from '@chakra-ui/react';
import IsDesktop from '../../utils/IsDesktop';
import client from '../../libs/client';
import { BASE_URL } from '../../helpers/constant';
import { useData } from '../../data';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useInterval } from '../../utils/useInterval';
import { Select } from 'antd';
import NewFilter from '../filter/NewFilter';
import dashboard from '../../public/icons/dashboard.svg';
import { useNavigate } from 'react-router-dom';
import NewSingleInvestor from './NewSingleInvestor';
import BrandButton from '../brandButton/BrandButton';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import 'swiper/css';
import 'swiper/css/navigation';
import '../../swiper.css';

const InvestorListAlt = ({ showViewAll = false }) => {
  const { isOpen, onToggle } = useDisclosure();
  const [selectedFounder, setSelectedFounder] = useState({});
  const isDesktop = IsDesktop();
  const [pageNo, setPageNo] = useState(1);
  const lastResultsCount = useRef(0);
  const [investors, setInvestors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilters] = useState({});
  const [selectedUserIndex, setSelectedUserIndex] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const { isOpen: viewDetailsIsOpen, onToggle: onToggleViewDetails } =
    useDisclosure();
  const loggedInUser = useSelector((state) => state?.user?.userInfo);
  const chatWithUserState = useSelector((state) => state?.chat?.chatInfo);
  const [recipient, setRecipient] = useState(chatWithUserState);
  const [recipientId, setRecipientId] = useState(chatWithUserState?.id);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [showMessageTray, setShowMessageTray] = useState(false);
  const { data } = useData('survey');

  const [searchInput, setSearchInput] = useState('');
  const [searchData, setSearchData] = useState([]);
  const { OptGroup } = Select;

  const [connections, setConnections] = useState([]);
  const {
    data: connectionsData,
    isLoading: connectionsLoading,
    refetch: refetchConnections,
  } = useData(
    `users/${loggedInUser?.id}/connections?page_no=1&page_size=10`,
    30000,
  );
  const {
    isOpen: chatBoxIsOpen,
    onOpen: onOpenChatBox,
    onClose: onCloseChatBox,
    onToggle: onToggleChatBox,
  } = useDisclosure();
  const investorsContainerRef = useRef(null);

  const [filterIsActive, setFilterIsActive] = useState(false);
  const [resetFilters, setResetFilters] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (connectionsData?.data) {
      setConnections(
        connectionsData?.data?.connections?.sort((a, b) =>
          moment(b?.lastMessage?.createdAt || b?.connection?.created_at).diff(
            moment(a?.lastMessage?.createdAt || a?.connection?.created_at),
          ),
        ),
      );
    }
    if (chatWithUserState) {
      openChatBox(chatWithUserState?.id, chatWithUserState);
    }
  }, [connectionsData?.data?.connections, chatWithUserState]);

  const openMessageTray = () => {
    setShowMessageTray((prev) => !prev);
  };

  const openChatBox = (recipientId, recipient) => {
    setRecipientId(recipientId);
    setRecipient(recipient);
    onOpenChatBox();
  };
  const closeChatBox = () => {
    setRecipientId(null);
    setRecipient(null);
    onToggleChatBox();
  };
  const isFromNavigate = () => {
    return (
      chatWithUserState &&
      chatWithUserState?.id === recipientId &&
      chatWithUserState?.connection?.accepted
    );
  };
  useInterval(() => {
    refetchConnections();
  }, 15000);

  const searchConnections = useCallback(
    (inputValue) => {
      setIsLoadingMessages(true);
      if (inputValue !== '') {
        setSearchData(
          connections?.filter((connection) =>
            `${connection?.first_name} ${connection?.last_name}`
              .toLowerCase()
              .includes(inputValue.toLowerCase()),
          ),
        );
      }
      setIsLoadingMessages(false);
    },
    [connections],
  );

  const searchResults = searchData?.map((user) => (
    <Select.Option key={user.id}>
      <Flex alignItems="center">
        <Avatar
          size="sm"
          name={`${user.first_name} ${user.last_name}`}
          src={user.profile_image}
        />
        <Text ml={4}>{`${user.first_name} ${user.last_name}`}</Text>
      </Flex>
    </Select.Option>
  ));

  const openSearchedUserChatBox = (userId) => {
    setRecipientId(userId);
    setRecipient(
      connections?.find(
        (connection) => connection?.id?.toString() === userId.toString(),
      ),
    );
    onOpenChatBox();
  };

  const handleScroll = (
    scrollpos = window.innerHeight + window.scrollY,
    containerHeight = document.documentElement.offsetHeight,
  ) => {
    let userScrollHeight = scrollpos;
    let windowBottomHeight = containerHeight;

    if (userScrollHeight >= windowBottomHeight) {
      if (lastResultsCount.current < 1) {
        return;
      } else {
        let _pageNo = pageNo + 1;
        setPageNo(_pageNo);
        getInvestors({ pageNumber: _pageNo });
      }
    }
  };

  const updateFilter = (data) => {
    setFilters((prev) => ({
      ...prev,
      ...data,
    }));
  };

  const updateState = (data, append = true, isFetchedFromServer = true) => {
    if (isFetchedFromServer) lastResultsCount.current = data.length;
    let _investors = append ? [...investors, ...data] : [...data];
    setIsLoading(false);
    setInvestors([..._investors]);
  };

  const parseFilters = (obj) => {
    const params = {
      companyStages: 'company_stage',
      fundCategories: 'fund_category',
      locations: 'incorporation_location',
      businessModels: 'business_model',
      categories: 'business_category',
    };
    return Object.entries(obj)
      .map(([key, val]) => `${params[key]}=${encodeURIComponent(val)}`)
      .join('&');
  };

  const getInvestors = ({
    filters = filter,
    append = true,
    pageNumber = 1,
  }) => {
    const _filters = parseFilters(filters);
    return client()
      .get(
        `${BASE_URL}user-types/investor?page_no=${pageNumber}&page_size=10${_filters ? `&${_filters}` : ''}`,
      )
      .then((res) => {
        setIsLoading(true);
        let data = res.data.data.users;
        updateState(data, append);
      });
  };

  useEffect(() => {
    getInvestors({});
  }, []);

  const onSelectUser = (id) => {
    setSelectedUserIndex(id);
  };

  useEffect(() => {
    onSelectUser(0);
  }, [isLoading]);

  const handleResetFilter = () => {
    setResetFilters(true);
  };

  useEffect(() => {
    let status = false;
    for (let key of Object.keys(filter)) {
      if (filter[key]?.length > 0) {
        status = true;
        break;
      }
    }
    setFilterIsActive(status);
    setResetFilters(false);
  }, [filter]);

  return (
    <Flex className="flex-col border bg-white mb-4 rounded-lg pb-2">
      {!isOpen ? (
        <>
          {!viewDetailsIsOpen ? (
            <Flex
              style={{ boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.05)' }}
              className="flex-row p-3 mb-4 items-start justify-between"
            >
              <Box minW="100px">
                <Text
                  color="brand.primary"
                  className={`pt-1 text-2xl font-semibold`}
                >
                  Connect to Investors
                </Text>
                <Box className="flex flex-wrap gap-4 my-3 items-center">
                  <NewFilter
                    reset={resetFilters}
                    multichoice
                    section="Company Stages"
                    onChange={updateFilter}
                    onApply={(data) => {
                      getInvestors({ filter: data, append: false });
                    }}
                    filters={
                      data?.data?.companyStages
                        ? { companyStages: data?.data?.companyStages }
                        : null
                    }
                  />
                  <NewFilter
                    reset={resetFilters}
                    multichoice
                    section="Funding Categories"
                    onChange={updateFilter}
                    onApply={(data) => {
                      getInvestors({ filter: data, append: false });
                    }}
                    filters={
                      data?.data?.fundCategories
                        ? { fundCategories: data?.data?.fundCategories }
                        : null
                    }
                  />
                  <NewFilter
                    reset={resetFilters}
                    multichoice
                    section="Locations"
                    onChange={updateFilter}
                    onApply={(data) => {
                      getInvestors({ filter: data, append: false });
                    }}
                    filters={
                      data?.data?.locations
                        ? { locations: data?.data?.locations }
                        : null
                    }
                  />
                  {filterIsActive ? (
                    <Box>
                      <BrandButton
                        text="Reset Filters"
                        onClick={handleResetFilter}
                        colorScheme="blue"
                        w={'100%'}
                        className="w-full max-w-min"
                      />
                    </Box>
                  ) : null}
                </Box>
              </Box>
              <Box
                onClick={() => {
                  navigate('/investors');
                }}
                className={`gap-2 py-1 cursor-pointer items-center font-medium hover:font-semibold ${showViewAll ? 'flex' : 'hidden'}`}
              >
                <Box className="sm:pr-2 sm:border-r">
                  <img
                    className="h-3.5 w-3.5"
                    src={dashboard}
                    alt="dashboard-icon"
                  />
                </Box>
                <Text className="hidden sm:block text-green-800 text-sm">
                  View all
                </Text>
              </Box>
            </Flex>
          ) : null}
        </>
      ) : null}
      <Box
        ref={investorsContainerRef}
        // onScroll={() => {handleScroll(investorsContainerRef.current.scrollTop + investorsContainerRef.current.clientHeight, investorsContainerRef.current.scrollHeight)}}
        className={`hide-scroll max-h-[90vh] overflow-x-auto pt-4 md:px-5 pb-7 pr-10`}
      >
        {!isLoading ? (
          <Box className="relative px-4">
            <Swiper
              modules={[Navigation]}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
              slidesPerView={'auto'}
              spaceBetween={12}
              className="investors-swiper"
              breakpoints={{
                // when window width is >= 320px
                320: {
                  slidesPerView: 1,
                },
                // when window width is >= 640px
                640: {
                  slidesPerView: 2,
                },
                // when window width is >= 1350px
                1350: {
                  slidesPerView: 3,
                },
                // when window width is >= 1800px
                1800: {
                  slidesPerView: 4,
                },
                // when window width is >= 2300px
                2300: {
                  slidesPerView: 5,
                },
              }}
            >
              {(investors?.length > 0) &
              (
                <div className="swiper-button-prev !w-8 !h-8 !bg-white !rounded-full !shadow-md !block">
                  <IoIosArrowBack className="!text-[#134A70] !text-xl" />
                </div>
              )}

              {(investors?.length > 0) &
              (
                <div className="swiper-button-next !w-8 !h-8 !bg-white !rounded-full !shadow-md !block">
                  <IoIosArrowForward className="!text-[#134A70] !text-xl" />
                </div>
              )}

              {investors && investors?.length > 0 ? (
                investors.map((investor, index) => (
                  <>
                    <SwiperSlide
                      className="w-full"
                      key={index}
                      style={{ width: 'auto' }}
                    >
                      <NewSingleInvestor
                        setSelectedFounder={setSelectedFounder}
                        investor={investor}
                        isOpen={viewDetailsIsOpen}
                        onToggle={onToggleViewDetails}
                        isSwiper={true}
                      />
                    </SwiperSlide>
                  </>
                ))
              ) : (
                <Flex className="text-center justify-center my-2 w-full">
                  No investors available yet!
                </Flex>
              )}
            </Swiper>
          </Box>
        ) : (
          <Spinner />
        )}
      </Box>
    </Flex>
  );
};
export default InvestorListAlt;
