import { Box } from '@chakra-ui/react';
import { memo, useCallback, useMemo } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { sanitizeHtml, isValidEmbedCode } from '../../utils/sanitizeHtml';

// Move regex patterns outside component to prevent recreation
const YOUTUBE_REGEX = /(?:youtube\.com\/.*(?:\?|&)v=|youtu\.be\/)([^&]+)/;
const VIMEO_REGEX = /vimeo\.com\/(\d+)/;
const VIDEO_URL_REGEX = /(?:youtube\.com|youtu\.be|vimeo\.com)/;

const MediaDisplay = memo(({ media = [] }) => {
  // Memoized URL checking functions
  const isVideoUrl = useCallback((url) => VIDEO_URL_REGEX.test(url), []);

  const extractYoutubeVideoId = useCallback((url) => {
    const match = url.match(YOUTUBE_REGEX);
    return match ? match[1] : null;
  }, []);

  const extractVimeoVideoId = useCallback((url) => {
    const match = url.match(VIMEO_REGEX);
    return match ? match[1] : null;
  }, []);

  // Memoized media renderer
  const renderMediaItem = useCallback(
    (item, key) => {
      if (isValidEmbedCode(item)) {
        const sanitized = sanitizeHtml(item);
        return (
          <LazyLoadComponent>
            <div dangerouslySetInnerHTML={{ __html: sanitized }} />
          </LazyLoadComponent>
        );
      }

      if (isVideoUrl(item)) {
        if (item.includes('youtube') || item.includes('youtu.be')) {
          const videoId = extractYoutubeVideoId(item);
          if (videoId) {
            return (
              <LazyLoadComponent>
                <iframe
                  key={key || item}
                  className="w-full h-full border-0"
                  src={`https://www.youtube.com/embed/${videoId}`}
                  title="YouTube video player"
                  loading="lazy"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </LazyLoadComponent>
            );
          }
        } else if (item.includes('vimeo')) {
          const videoId = extractVimeoVideoId(item);
          if (videoId) {
            return (
              <LazyLoadComponent>
                <iframe
                  key={key || item}
                  className="w-full h-full border-0"
                  src={`https://player.vimeo.com/video/${videoId}`}
                  title="Vimeo video player"
                  loading="lazy"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                />
              </LazyLoadComponent>
            );
          }
        }
      }

      return (
        <LazyLoadComponent>
          <img
            key={key || item}
            className="w-full h-full object-cover"
            src={item}
            alt="media"
            loading="lazy"
          />
        </LazyLoadComponent>
      );
    },
    [isVideoUrl, extractYoutubeVideoId, extractVimeoVideoId],
  );

  // Memoize the layout logic
  const mediaLayout = useMemo(() => {
    switch (media.length) {
      case 0:
        return null;
      case 1:
        return (
          <Box className="mb-2">
            {media.map((item, index) => renderMediaItem(item, index))}
          </Box>
        );
      case 2:
        return (
          <Box className="grid grid-cols-2 gap-2 mb-2">
            {media.map((item, index) => renderMediaItem(item, index))}
          </Box>
        );
      case 3:
        return (
          <Box className="grid grid-cols-2 gap-2 mb-2">
            {renderMediaItem(media[0], 0)}
            <Box className="flex flex-col gap-2">
              {[media[1], media[2]].map((item, index) =>
                renderMediaItem(item, index + 1),
              )}
            </Box>
          </Box>
        );
      default:
        return (
          <Box className="grid grid-cols-2 gap-2 mb-2">
            <Box className="flex flex-col gap-2">
              {[media[0], media[1]].map((item, index) =>
                renderMediaItem(item, index),
              )}
            </Box>
            <Box className="flex flex-col gap-2">
              {[media[2], media[3]].map((item, index) =>
                renderMediaItem(item, index + 2),
              )}
            </Box>
          </Box>
        );
    }
  }, [media, renderMediaItem]);

  return <>{mediaLayout}</>;
});

MediaDisplay.displayName = 'MediaDisplay';

export default MediaDisplay;
