import DOMPurify from 'dompurify';

// Configure DOMPurify to allow specific tags and attributes for embeds
const config = {
  ALLOWED_TAGS: ['iframe', 'img', 'div', 'a', 'p', 'br', 'span'],
  ALLOWED_ATTR: [
    'src',
    'height',
    'width',
    'frameborder',
    'allowfullscreen',
    'allow',
    'alt',
    'title',
    'class',
    'style',
  ],
  ALLOWED_URI_REGEXP:
    /^(?:(?:(?:f|ht)tps?|mailto|tel|callto|cid|xmpp|xxx):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))/i,
  ADD_TAGS: ['iframe'],
  ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
};

// Initialize DOMPurify with custom config
DOMPurify.setConfig(config);

export const sanitizeHtml = (html) => {
  if (!html) return '';
  return DOMPurify.sanitize(html, config);
};

// Validate if the content is an embed code
export const isValidEmbedCode = (content) => {
  const sanitized = sanitizeHtml(content);
  return (
    sanitized.includes('<iframe') ||
    sanitized.includes('<img') ||
    sanitized.includes('<div class="embed')
  );
};
